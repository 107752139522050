import { useRef, useState } from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import AttachmentIcon from '@/assets/icons/attachment_icon.svg?react';
import { PhotoFileType } from '@/types/user.ts';
import { uploadFile } from '@/api/user/fetchers.ts';
import * as Sentry from '@sentry/react';
import { resizeImage } from '@/utils/resizeImage.ts';
import { MAX_ATTACHMENTS_LENGTH } from '@/constants';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';

const MAX_FILE_SIZE_MB = 100;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

interface AttachmentsInputProps {
  mediaFiles: PhotoFileType[];
  onMediaChange: (mediaFiles: PhotoFileType[]) => void;
  disabled?: boolean;
}

export const AttachmentsInput = ({
  mediaFiles,
  onMediaChange,
  disabled,
}: AttachmentsInputProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (file: File) => {
    // Enforce max file size limit (100MB)
    if (file.size > MAX_FILE_SIZE_BYTES) {
      toast.error(t('common.maxFileSizeError', { size: MAX_FILE_SIZE_MB }));
      return;
    }

    // Handle max attachments length exceeded
    if (MAX_ATTACHMENTS_LENGTH === mediaFiles.length) {
      toast.warning(t('common.maxAttachmentsWarn'));
      return;
    }

    setLoading(true);
    let processedFile = file;

    // Check if the file is an image
    if (file.type.startsWith('image/')) {
      processedFile = await resizeImage(file); // Resize only if it's an image
    }

    const formData = new FormData();
    formData.append('file', processedFile);
    try {
      const response = await uploadFile(formData);
      if (response?.file) {
        const updatedMediaFiles = [...mediaFiles, response?.file];
        onMediaChange(updatedMediaFiles);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            await handleFileUpload(file);
          }
        }}
        style={{ display: 'none' }}
        accept="*/*"
      />

      {!disabled && (
        <IconButton
          onClick={() => {
            if (!loading) {
              fileInputRef.current?.click();
            }
          }}
        >
          {!loading ? (
            <AttachmentIcon />
          ) : (
            <CircularProgress style={{ width: '30px', height: '30px' }} />
          )}
        </IconButton>
      )}
    </Box>
  );
};
