import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@/store/authStore.ts';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import { isHirer } from '@/utils/roleCheck.ts';

const CreationFormButtons = () => {
  const role = useAuthStore((state) => state.role);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleCancelClick = () => {
    navigate(
      `/${PAGE_ROUTES.Explore}/${
        isHirer(role) ? PAGE_ROUTES.ExploreOfferings : PAGE_ROUTES.ExploreJobs
      }`
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        gap: '40px',
        marginTop: '40px',
        width: '100%',
      }}
    >
      <Button sx={{ minWidth: '200px' }} variant={'contained'} type={'submit'}>
        {t('form.submit')}
      </Button>
      <Button
        onClick={handleCancelClick}
        sx={{ minWidth: '200px' }}
        variant={'outlined'}
      >
        {t('form.cancel')}
      </Button>
    </Box>
  );
};

export default CreationFormButtons;
