import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { OFFERING_TYPE } from '@/constants';
import OfferingDetailsStep from '@/pages/OfferingCreationPage/components/OfferingDetailsStep.tsx';
import CategoriesStep from '@/components/common/JobPostingForm/CategoriesStep.tsx';
import SubcategoriesStep from '@/components/common/JobPostingForm/SubcategoriesStep.tsx';
import {
  useCategoriesQuery,
  useSubcategoriesQuery,
} from '@/api/jobPosting/queries';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';
import i18n from '@/i18n';
import { OfferingType } from '@/api/offering/types';

const offeringSchema = z.object({
  title: z.string().min(1),
  description: z.string().min(1),
  type: z.enum(OFFERING_TYPE, {
    errorMap: () => ({
      message: i18n.t('validation.pleaseSelectOfferingType'),
    }),
  }),
  price: z.coerce
    .number()
    .min(1, { message: i18n.t('validation.required', { min: 1 }) }),
  skills: z.array(z.string()).min(1, {
    message: i18n.t('validation.required'),
  }),
  mediaIds: z.array(z.string()).optional(),
  private: z.boolean().optional(),
  selectedCategoryId: z.string().min(1, {
    message: i18n.t('validation.youMustSelectACategory'),
  }),
  subCategoryId: z.string(),
});

export type OfferingFormInputs = z.infer<typeof offeringSchema>;

type Props = {
  offering?: OfferingType;
  onSubmit: (data: OfferingFormInputs) => void;
};

const OfferingCreationForm = ({ offering, onSubmit }: Props) => {
  const currentStep = useCreationFormStepsStore((state) => state.currentStep);
  const setPrevNextStep = useCreationFormStepsStore(
    (state) => state.setPrevNextStep
  );

  const methods = useForm<OfferingFormInputs>({
    resolver: zodResolver(offeringSchema),
    defaultValues: {
      type: 'FIXED',
      price: 1,
    },
  });
  const { watch, handleSubmit, reset } = methods;
  const selectedCategoryId = watch('selectedCategoryId');

  const { data: categories } = useCategoriesQuery();
  const { data: subcategories } = useSubcategoriesQuery(selectedCategoryId);

  useEffect(() => {
    if (offering) {
      reset({
        selectedCategoryId: offering.subCategory?.categoryId,
        title: offering.title,
        description: offering.description,
        type: offering.type,
        skills: offering.skills,
        mediaIds: offering.medias?.map((el) => el?.id),
        private: false,
        price: offering.price,
        subCategoryId: offering.subCategory?.id,
      });
    }
  }, [offering]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 1 && <CategoriesStep categories={categories || []} />}
        {currentStep === 2 && (
          <SubcategoriesStep
            onNextButton={() => {
              setPrevNextStep(1);
            }}
            subcategories={subcategories || []}
          />
        )}
        {currentStep === 3 && (
          <OfferingDetailsStep initialMediaFiles={offering?.medias} />
        )}
      </form>
    </FormProvider>
  );
};

export default OfferingCreationForm;
