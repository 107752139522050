import { Box, CircularProgress, Typography } from '@mui/material';
import ChatMessageItem from '@/pages/ChatsPage/components/ChatMessageItem.tsx';
import dayjs from 'dayjs';
import { StyledProgressLoader } from '@/styled';
import { RefObject, useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchMessages, readMessagesReq } from '@/api/chat/fetchers.ts';
import EmptyMessages from '@/pages/ChatsPage/components/EmptyMessages.tsx';
import { PublicUserType } from '@/api/jobPosting/types.ts';
import {
  RoomsStateType,
  useRoomsMessagesStore,
} from '@/store/roomsMessagesStore.ts';
import { useTranslation } from 'react-i18next';
import { MessageWithAttachments, MessageWithCursor } from '@/api/chat/types.ts';

const ChatMessages = ({
  chatContainerRef,
  roomId,
  isInitiatedRoom,
  isEmptyRooms,
  currentSelectedUser,
  roomData,
  searchedMessage,
}: {
  roomId: string;
  isInitiatedRoom: boolean;
  isEmptyRooms: boolean;
  currentSelectedUser: PublicUserType;
  chatContainerRef: RefObject<HTMLDivElement>;
  roomData?: RoomsStateType;
  searchedMessage?: MessageWithCursor;
}) => {
  const searchValue = useRoomsMessagesStore((state) => state.searchValue);
  const isSearchMode = !!searchValue;
  const { t } = useTranslation();
  const setRoomsData = useRoomsMessagesStore((state) => state.setRoomsData);
  const initialLoad = roomData?.initialLoad ?? true;

  const cursor =
    searchedMessage?.cursor && initialLoad
      ? searchedMessage?.cursor
      : (roomData?.cursor ?? '');

  const messagesWithAttachments = roomData?.messages ?? [];
  const hasNextPage = roomData?.hasNextPage ?? false;
  const queryClient = useQueryClient();

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ['messages'],
    queryFn: () => fetchMessages(roomId, cursor),
    enabled:
      !!roomId &&
      !isInitiatedRoom &&
      initialLoad &&
      (isSearchMode ? !!searchedMessage : true), // If search mode searchedMessage must be persisted
    refetchOnWindowFocus: false,
    retry: false,
  });

  const readMessage = useMutation({
    mutationFn: readMessagesReq,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      queryClient.invalidateQueries({ queryKey: ['rooms'] });
    },
  });

  useEffect(() => {
    if (data && data?.endCursor !== roomData?.cursor) {
      const container = chatContainerRef.current;

      // Save the current scroll position
      const prevScrollHeight = container?.scrollHeight ?? 0;
      const reversedMessages = data.messages
        ? [...data.messages].reverse()
        : [];

      // If search mode and searchedMessage persisted -> room id is cursor, else room id is room.id
      const stateRoomId = searchedMessage ? searchedMessage.cursor : roomId;
      // Prepend the messages
      setRoomsData(stateRoomId, {
        messages: [
          ...reversedMessages,
          ...(searchedMessage && initialLoad
            ? [{ message: searchedMessage }]
            : []),
          ...messagesWithAttachments,
        ] as MessageWithAttachments[],
        cursor: data.endCursor,
        hasNextPage: data.hasNextPage,
        initialLoad: false,
      });

      // Read last message
      const lastMessage = reversedMessages[reversedMessages.length - 1];
      if (!lastMessage?.message?.read && !searchedMessage) {
        readMessage.mutate(lastMessage?.message?.id);
      }

      // Adjust the scroll position after new messages are loaded
      setTimeout(() => {
        if (container) {
          const newScrollHeight = container?.scrollHeight || 0;
          const heightDifference = newScrollHeight - prevScrollHeight;
          container.scrollTop += heightDifference; // Account for the loader height
        }
      }, 0);
    }
  }, [data]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container && messagesWithAttachments.length > 0 && initialLoad) {
      container.scrollTop = container.scrollHeight; // Initially scroll to the bottom
    }
  }, [cursor]);

  const formatDate = (date: Date) => dayjs(date).format('MMMM D');

  const handleScroll = useCallback(() => {
    const container = chatContainerRef.current;
    if (container) {
      const canScroll = container.scrollHeight > container.clientHeight;
      if (canScroll && container.scrollTop === 0 && hasNextPage && !isLoading) {
        refetch(); // Load more messages
      }
    }
  }, [hasNextPage, isLoading, refetch]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  if (!messagesWithAttachments?.length || isInitiatedRoom || isEmptyRooms) {
    return isRefetching || isLoading ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      <EmptyMessages isEmptyRooms={isEmptyRooms} />
    );
  }
  return (
    <>
      {!hasNextPage && (
        <Typography
          sx={{
            color: '#92979D',
            display: 'block',
            textAlign: 'center',
            paddingBottom: '14px',
            borderBottom: '1px solid #ecedf1',
          }}
          variant={'poppins16Regular'}
        >
          {t('common.historyTop')}
        </Typography>
      )}
      {isRefetching && (
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            minHeight: '50px',
            position: 'relative',
          }}
        >
          <StyledProgressLoader />
        </Box>
      )}
      {messagesWithAttachments.map((messageWithAttachments, index) => {
        const message = messageWithAttachments.message;
        const showDateLabel =
          index === 0 ||
          formatDate(messagesWithAttachments[index].message.createdAt) !==
            formatDate(messagesWithAttachments[index - 1].message.createdAt);

        return (
          <Box key={message.id}>
            {showDateLabel && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '30px',
                }}
              >
                <Typography
                  sx={{
                    display: 'inline-flex',
                    textAlign: 'center',
                    backgroundColor: 'purple1',
                    color: 'white',
                    borderRadius: '50px',
                    padding: '10px 20px',
                  }}
                  variant={'poppins14Regular'}
                >
                  {formatDate(message.createdAt)}
                </Typography>
              </Box>
            )}
            <ChatMessageItem
              isMineMessage={message.senderId !== currentSelectedUser?.id}
              name={message.sender?.displayName ?? ''}
              message={message.message}
              date={message.createdAt}
              srcAvatar={message.sender?.photo?.url ?? ''}
              attachments={messageWithAttachments.attachments}
            />
          </Box>
        );
      })}
    </>
  );
};

export default ChatMessages;
