import { StyledProgressLoader, StyledTruncatedTypography } from '@/styled';
import { Box, Button, Typography } from '@mui/material';
import ProfileWrapper from '@/components/common/ProfileWrapper';
import LocationIcon from '@/assets/icons/location_icon.svg?react';
import SocialLinksButtons from '@/components/common/SocialLinksButtons';
import { getAvailableForLabel } from '@/utils/getAvailableForLabel';
import { getDatingLocationLabel } from '@/utils/getDatingLocationLabel';
import { PublicUserType } from '@/api/jobPosting/types';
import { getLocationString } from '@/utils/getLocationString';
import { useTranslation } from 'react-i18next';
import { useUserProfile } from '@/api/user/queries';
import EditProfileInfo from '@/pages/ProfilePage/components/talent/EditProfileInfo.tsx';
import { toast } from 'sonner';
import { PAGE_ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/store/authStore.ts';
import { isHirer } from '@/utils/roleCheck.ts';
import { userInitiateRoomUserStore } from '@/store/initiateRoomUserStore.ts';
import ProfileAvatar from '@/pages/ProfilePage/components/ProfileAvatar.tsx';

type Props = {
  talent?: PublicUserType;
  isMyProfile: boolean;
  isOfferingDetails?: boolean;
};

const OfferingDetailsProfile = ({
  talent,
  isMyProfile,
  isOfferingDetails = false,
}: Props) => {
  const role = useAuthStore((state) => state.role);
  const setUser = userInitiateRoomUserStore((state) => state.setUser);
  const { t } = useTranslation();
  const { data: profile, isLoading } = useUserProfile(true);
  const currentProfile = isMyProfile && profile ? profile : talent;
  const navigate = useNavigate();

  if (isLoading) {
    return <StyledProgressLoader />;
  }

  if (!currentProfile) {
    toast.error('Failed to load profile information');
    return <></>;
  }

  const parsedLocation = currentProfile.location
    ? getLocationString(currentProfile.location)
    : '';
  const name = currentProfile.displayName ?? '';

  const initiateRoom = () => {
    if (!currentProfile.roomId) {
      setUser(currentProfile);
      navigate(`/${PAGE_ROUTES.Chats}`);
    } else {
      navigate(`/${PAGE_ROUTES.Chats}/${currentProfile.roomId}`);
    }
  };

  return (
    <ProfileWrapper sx={{ padding: 0 }}>
      <Box
        sx={{
          width: '100%',
          padding: '30px 40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {isMyProfile && profile && (
          <Box sx={{ position: 'absolute', top: '28px', right: '28px' }}>
            <EditProfileInfo profile={profile} />
          </Box>
        )}
        <ProfileAvatar
          verified={currentProfile.verified}
          name={name}
          avatarSrc={currentProfile.photo?.url}
        />
        <StyledTruncatedTypography
          sx={{
            width: '100%',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '22px',
          }}
          variant={'poppins30Semibold'}
        >
          {name}
        </StyledTruncatedTypography>
        <Typography
          variant={'poppins20Semibold'}
          sx={{ color: 'purple1', textAlign: 'center' }}
        >
          {currentProfile.position}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '3px',
            marginTop: '8px',
            alignItems: 'center',
          }}
        >
          <LocationIcon style={{ width: '22px', height: '22px' }} />
          <Typography sx={{ textAlign: 'center' }} variant={'poppins20Medium'}>
            {parsedLocation}
          </Typography>
        </Box>
        {!isMyProfile && isOfferingDetails && (
          <>
            {isHirer(role) && (
              <Button
                onClick={initiateRoom}
                sx={{ marginTop: '22px', width: '100%' }}
                variant={'contained'}
              >
                {t('common.message')}
              </Button>
            )}
            <Button
              onClick={() => {
                navigate(`/${PAGE_ROUTES.Talent}/${currentProfile.id}`);
              }}
              sx={{ marginTop: '22px', width: '100%' }}
              variant={'contained'}
            >
              {t('common.viewProfile')}
            </Button>
          </>
        )}
        <Box
          sx={{
            marginTop: '18px',
            display: 'flex',
            gap: { xs: '20px', sm: '20px', md: '40px', lg: '45px' },
          }}
        >
          <SocialLinksButtons socials={currentProfile.socialLinks || []} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '22px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '9px',
          backgroundColor: 'gray5',
        }}
      >
        <Typography variant={'poppins18Medium'} sx={{ color: '#92979D' }}>
          {t('form.dating')}:
        </Typography>
        <Typography variant={'poppins20Medium'}>
          {currentProfile.dating ? t('form.preferred') : t('common.no')}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-around', gap: '50px' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: '18px',
          }}
        >
          <Typography variant={'poppins18Medium'} sx={{ color: '#92979D' }}>
            {t('form.accepts')}
          </Typography>
          <Typography
            sx={{
              marginTop: '5px',
            }}
            variant={'poppins20Medium'}
          >
            {getAvailableForLabel(currentProfile.availableFor, t)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: '18px',
          }}
        >
          <Typography variant={'poppins18Medium'} sx={{ color: '#92979D' }}>
            {t('form.location')}
          </Typography>
          <Typography
            sx={{
              marginTop: '5px',
            }}
            variant={'poppins20Medium'}
          >
            {getDatingLocationLabel(currentProfile.datingLocation, t)}
          </Typography>
        </Box>
      </Box>
    </ProfileWrapper>
  );
};

export default OfferingDetailsProfile;
