import { fetchRooms, fetchSearchRooms } from '@/api/chat/fetchers.ts';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useRoomsQuery = () => {
  return useInfiniteQuery({
    queryKey: ['rooms'],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => fetchRooms({ page: `${pageParam}` }),
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        return lastPage?.page < lastPage?.totalPages
          ? lastPage?.page + 1
          : undefined;
      }
    },
    retry: false,
  });
};

export const useSearchRoomsQuery = (searchValue: string) => {
  return useInfiniteQuery({
    queryKey: ['searchRooms', searchValue],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      fetchSearchRooms({ page: `${pageParam}`, query: searchValue }),
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        return lastPage?.page < lastPage?.totalPages
          ? lastPage?.page + 1
          : undefined;
      }
    },
    retry: false,
    enabled: !!searchValue, // Only run when there's a search value
  });
};
