import { create } from 'zustand';
import { MessageWithAttachments } from '@/api/chat/types.ts';

export type RoomsStateType = {
  initialLoad: boolean;
  cursor: string | undefined;
  hasNextPage: boolean;
  messages: MessageWithAttachments[];
};

type State = {
  searchValue: string;
  roomsData: {
    [roomId: string]: RoomsStateType;
  };
};

type Actions = {
  setSearchValue: (value: string) => void;
  setRoomsData: (
    roomId: string,
    data: Partial<State['roomsData'][string]>
  ) => void;
  clearRoomData: () => void
};

export const useRoomsMessagesStore = create<State & Actions>((set) => ({
  searchValue: '',
  setSearchValue: (value: string) => {
    set(() => ({
      searchValue: value,
    }));
  },
  roomsData: {},
  setRoomsData: (roomId, data) => {
    set((state) => {
      // Check if roomId exists in roomsData, if not, initialize it
      const currentRoomData = state.roomsData[roomId] || {
        initialLoad: false,
        cursor: undefined,
        hasNextPage: true,
        messages: [],
      };

      // Merge the current room data with the new data (updating only the necessary fields)
      const updatedRoomData = {
        ...currentRoomData,
        ...data,
      };

      return {
        roomsData: {
          ...state.roomsData,
          [roomId]: updatedRoomData,
        },
      };
    });
  },
  clearRoomData : () => {
    set((state) => ({ ...state, roomsData: {} }));
  },
}));
