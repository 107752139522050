import { Box, Typography } from '@mui/material';
import FileIcon from '@/assets/icons/file_icon.svg?react';
import { getFileExtension } from '@/utils/getFileExtension.ts';

type Props = {
  size: number;
  fileName?: string;
};

const OtherFilePlaceholder = ({ size, fileName }: Props) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '3px',
        backgroundColor: '#DBE0FF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <FileIcon />
      <Typography sx={{color: '#4D63F5'}} variant={'poppins13Bold'}>
        {getFileExtension(fileName ?? '')}
      </Typography>
    </Box>
  );
};

export default OtherFilePlaceholder;
