import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { z } from 'zod';

import en from './locales/en.json';
import es from './locales/es.json';
import zh from './locales/zh.json';
import tl from './locales/tl.json';
import vi from './locales/vi.json';
import fr from './locales/fr.json';
import ar from './locales/ar.json';
import ko from './locales/ko.json';
import ru from './locales/ru.json';
import de from './locales/de.json';
import it from './locales/it.json';
import translationZh from './locales/zod/zh/zod.json';
import translationTl from './locales/zod/tl/zod.json';
import translationVi from './locales/zod/vi/zod.json';
import translationEn from 'zod-i18n-map/locales/en/zod.json';
import translationEs from 'zod-i18n-map/locales/es/zod.json';
import translationFr from 'zod-i18n-map/locales/fr/zod.json';
import translationAr from 'zod-i18n-map/locales/ar/zod.json';
import translationKo from 'zod-i18n-map/locales/ko/zod.json';
import translationRu from 'zod-i18n-map/locales/ru/zod.json';
import translationDe from 'zod-i18n-map/locales/de/zod.json';
import translationIt from 'zod-i18n-map/locales/it/zod.json';

import { zodI18nMap } from 'zod-i18n-map';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // English Locale
import 'dayjs/locale/es'; // Spanish Locale
import 'dayjs/locale/zh'; // Chinese Locale
import 'dayjs/locale/tl-ph'; // Tagalog Locale
import 'dayjs/locale/vi'; // Vietnamese Locale
import 'dayjs/locale/fr'; // French Locale
import 'dayjs/locale/ar'; // Arabic Locale
import 'dayjs/locale/ko'; // Korean Locale
import 'dayjs/locale/ru'; // Russian Locale
import 'dayjs/locale/de'; // German Locale
import 'dayjs/locale/it'; // Italian Locale
import relativeTime from "dayjs/plugin/relativeTime";

const resources = {
  en: { translation: en, zod: translationEn },
  es: { translation: es, zod: translationEs },
  zh: { translation: zh, zod: translationZh },
  tl: { translation: tl, zod: translationTl },
  vi: { translation: vi, zod: translationVi },
  fr: { translation: fr, zod: translationFr },
  ar: { translation: ar, zod: translationAr },
  ko: { translation: ko, zod: translationKo },
  ru: { translation: ru, zod: translationRu },
  de: { translation: de, zod: translationDe },
  it: { translation: it, zod: translationIt },
};

i18n
  .use(LanguageDetector) // Detects language from browser settings
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // Fallback to English if language not found
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    debug: import.meta.env.DEV,
    parseMissingKeyHandler: (key) => {
      console.warn(`Missing translation key "${key}"`);
    },
  });

z.setErrorMap(zodI18nMap);

// Update Day.js locale dynamically based on i18n language change
const updateDayJsLocale = (lng: string) => {
  const supportedLocales = [
    'en',
    'es',
    'zh',
    'tl',
    'vi',
    'fr',
    'ar',
    'ko',
    'ru',
    'de',
    'it',
  ];
  if (supportedLocales.includes(lng)) {
    dayjs.locale(lng);
  } else {
    dayjs.locale('en'); // Default to English if unsupported locale
  }
};

dayjs.extend(relativeTime);

// Set Day.js locale based on the current language
updateDayJsLocale(i18n.language);

// Listen for language changes in i18n
i18n.on('languageChanged', (lng) => {
  updateDayJsLocale(lng);
});

// Export the configured Zod instance
export { z };

export default i18n;
