import { apiRequest } from '@/libs/apiRequest.ts';
import {
  MessageWithAttachments,
  RoomType,
  FetchMessagesResponse, SearchedRoomType,
} from '@/api/chat/types.ts';
import { Message } from '@/types/chat.ts';
import { PhotoFileType } from '@/types/user.ts';

export const fetchMessages = async (roomId: string, cursor?: string) => {
  const response = await apiRequest<FetchMessagesResponse>(
    `/rooms/${roomId}/messages`,
    {
      method: 'GET',
      withAuth: true,
      params: {
        after: cursor ?? '',
      },
    }
  );

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const sendMessagesReq = async ({
  roomId,
  message,
}: {
  roomId: string;
  message: { message?: string; attachments?: PhotoFileType['id'][] };
}) => {
  const response = await apiRequest<MessageWithAttachments>(
    `/rooms/${roomId}/messages`,
    {
      method: 'POST',
      withAuth: true,
      body: message,
    }
  );

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const initiateRoomReq = async ({
  userId,
  message,
}: {
  userId: string;
  message: string;
}) => {
  const response = await apiRequest<Message>(`/user/${userId}/message`, {
    method: 'POST',
    withAuth: true,
    body: { message },
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
export const fetchRooms = async (queryParams: { page?: string }) => {
  const response = await apiRequest<{
    data: RoomType[];
    page: number;
    totalPages: number;
  }>(`/rooms`, {
    method: 'GET',
    withAuth: true,
    params: queryParams,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
export const fetchSearchRooms = async (queryParams: { page?: string, query?: string }) => {
  const response = await apiRequest<{
    data: SearchedRoomType[];
    page: number;
    totalPages: number;
  }>(`/rooms/search`, {
    method: 'GET',
    withAuth: true,
    params: queryParams,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const readMessagesReq = async (messageId: string) => {
  const response = await apiRequest<Message>(`/message/${messageId}/read`, {
    method: 'POST',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
