import { Avatar, Box, Typography } from '@mui/material';
import { stringAvatar } from '@/utils/getAvatarString.ts';
import { StyledTruncatedTypography } from '@/styled';
import dayjs from 'dayjs';

type Props = {
  id?: string;
  name?: string;
  lastMessage?: string;
  lastSeenDate?: Date;
  avatarSrc?: string;
  isActive: boolean;
  onClick: () => void;
};

const RoomListItem = ({
  name,
  lastMessage,
  lastSeenDate,
  avatarSrc,
  onClick,
  isActive,
}: Props) => {
  const relativeTime = dayjs(lastSeenDate).fromNow(true);
  return (
    <Box
      onClick={onClick}
      sx={{
        padding: '10px 20px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fff',
          transition: 'background-color 0.3s ease',
        },
        borderBottom: '1px solid #D2D5E5',
        ...(isActive && {
          backgroundColor: '#fff',
          transition: 'background-color 0.3s ease',
        }),
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Avatar src={avatarSrc} {...stringAvatar(name ?? '')} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            overflow: 'hidden',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              overflow: 'hidden',
            }}
          >
            <StyledTruncatedTypography variant={'poppins14Bold'}>
              {name}
            </StyledTruncatedTypography>
            <StyledTruncatedTypography
              sx={{ color: '#92979D' }}
              variant={'poppins12Regular'}
            >
              {lastMessage}
            </StyledTruncatedTypography>
          </Box>
          {lastSeenDate && (
            <Typography
              sx={{ color: 'purple4', whiteSpace: 'nowrap' }}
              variant={'poppins10Regular'}
            >
              {relativeTime}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RoomListItem;
