import {
  Autocomplete,
  Box,
  debounce,
  FormControl,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { StyledTextField } from '@/styled';
import { StyledFormLabel } from '@/components/forms/FormLabel';

type FormAutoSuggestProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
  placeholder: string;
  options: { label: string; value: string }[];
  onDebounce?: (value: string) => void;
  searchInputValue?: string;
};

const FormAutoSuggest = <TSchema extends FieldValues>({
  name,
  label,
  control,
  options,
  onDebounce,
  placeholder,
  searchInputValue,
}: FormAutoSuggestProps<TSchema>) => {
  const [internalValue, setInternalValue] = useState(searchInputValue ?? '');
  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500
      ),
    []
  );

  useEffect(() => {
    if (internalValue !== searchInputValue) {
      setInternalValue(searchInputValue ?? '');
    }
  }, [searchInputValue]);

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <StyledFormLabel htmlFor={name}>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState: { errors },
        }) => {
          const locationSearchError = errors?.locationSearch?.message;
          const errorMessage =
            error?.message ||
            (typeof locationSearchError === 'string'
              ? locationSearchError
              : '') ||
            'helper text';
          return (
            <Box sx={{ position: 'relative', width: '100%' }}>
              <Autocomplete
                id={name}
                freeSolo
                options={options}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.label
                }
                filterOptions={(x) => x}
                autoComplete
                value={{ value: value || '', label: value || '' }}
                inputValue={internalValue}
                onInputChange={(_, newInputValue, reason) => {
                  if (reason === 'input' && onDebounce) {
                    setInternalValue(newInputValue);
                    handleDebouncedValue(newInputValue, onDebounce);
                  }
                }}
                disableClearable
                popupIcon={null}
                onChange={(_, newValue, reason) => {
                  if (
                    typeof newValue === 'object' &&
                    newValue !== null &&
                    'value' in newValue &&
                    'label' in newValue
                  ) {
                    onChange(newValue?.value || '');
                    if (reason === 'selectOption' && onDebounce) {
                      setInternalValue(newValue?.label);
                      handleDebouncedValue(newValue?.label, onDebounce);
                    }
                  }
                }}
                renderInput={(params) => (
                  <StyledTextField
                    placeholder={placeholder}
                    error={!!error || !!locationSearchError}
                    {...params}
                  />
                )}
              />
              <Typography
                variant={'error'}
                sx={{
                  opacity: error?.message ?? !!locationSearchError ? 1 : 0,
                }}
              >
                {errorMessage}
              </Typography>
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormAutoSuggest;
