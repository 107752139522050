import { ProtectedRoute } from '@/components/common/ProtectedRoutes/ProtectedRoutes.tsx';
import { ReactNode } from 'react';
import { useAuthStore } from '@/store/authStore';
import { AccessCheckFn } from '@/types/common';

interface Props {
  children: ReactNode;
  isProtected?: boolean;
  accessCheck?: AccessCheckFn;
  redirectTo?: string;
}

export const ProtectedRouteWrapper = ({
  children,
  isProtected,
  redirectTo = 'signup',
  accessCheck,
}: Props) => {
  const { isAuth, isEmailVerified, isProfileCompleted, role, verification } =
    useAuthStore();

  const isAllowed =
    !isProtected ||
    accessCheck?.(isAuth, isEmailVerified, isProfileCompleted, role, verification);

  return (
    <ProtectedRoute isAllowed={!!isAllowed} redirectTo={redirectTo}>
      {children}
    </ProtectedRoute>
  );
};
