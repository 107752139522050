import { Box, IconButton } from '@mui/material';
import { StyledTextField, StyledTruncatedTypography } from '@/styled';
import { useTranslation } from 'react-i18next';
import { BoxIcon } from '@/components/forms/FormSocialLinks';
import SendMessageIcon from '@/assets/icons/send_message_icon.svg?react';
import { handleMutationError } from '@/utils/handleMutationError.ts';
import React, { RefObject, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { initiateRoomReq, sendMessagesReq } from '@/api/chat/fetchers.ts';
import {IMAGE_PATTERN, MAX_MESSAGE_LENGTH, PAGE_ROUTES} from '@/constants';
import EmojiSection from '@/pages/ChatsPage/components/EmojiSection.tsx';
import { userInitiateRoomUserStore } from '@/store/initiateRoomUserStore.ts';
import { useNavigate } from 'react-router-dom';
import { AttachmentsInput } from '@/components/AttachmentsInput';
import { PhotoFileType } from '@/types/user.ts';
import RemoveButton from '@/components/common/RemoveButton';
import { styled } from '@mui/material/styles';
import OtherFilePlaceholder from '@/components/common/OtherFilePlaceholder';
import { MessageWithAttachments } from '@/api/chat/types.ts';

export const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  minHeight: '92px',
  borderRadius: '10px',
  height: '92px',
});

export const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '10px',
});

type Props = {
  roomId: string;
  initiateRoomId?: string;
  isInitiatedRoom: boolean;
  chatContainerRef: RefObject<HTMLDivElement>;
  setNewMessage: (newMessage: MessageWithAttachments) => void;
};

const ChatInputSection = ({
  isInitiatedRoom,
  initiateRoomId,
  roomId,
  chatContainerRef,
  setNewMessage,
}: Props) => {
  const navigate = useNavigate();
  const setInitiatedUserRoom = userInitiateRoomUserStore(
    (state) => state.setUser
  );
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<PhotoFileType[]>([]);

  const queryClient = useQueryClient();
  const sendMessage = useMutation({
    mutationFn: sendMessagesReq,
    onSuccess: (data) => {
      if (data) {
        setNewMessage(data);
        queryClient.invalidateQueries({ queryKey: ['rooms'] });
        scrollToBottom();
      }
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const initiateRoom = useMutation({
    mutationFn: initiateRoomReq,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['rooms'] });
      setInitiatedUserRoom(null);
      navigate(`/${PAGE_ROUTES.Chats}/${data?.roomId}`);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const resetInput = () => {
    setMessage('');
    setAttachments([]);
  };

  const handleSendMessage = async () => {
    if (!message.trim() && !attachments.length) return;

    const attachmentsIds = attachments.map((el) => el.id);

    // Helper function to send a single message
    const sendSingleMessage = async (
      text: string,
      attach: PhotoFileType['id'][]
    ) => {
      sendMessage.mutate({
        roomId,
        message: { ...(text && { message: text }), attachments: attach }, // Attachments are included only for the last chunk or when no text is present
      });
    };

    // Handle case: Only attachments, no message
    if (!message.trim() && attachmentsIds.length) {
      await sendSingleMessage('', attachmentsIds);
      resetInput();
      return;
    }

    // Split message into chunks based on MAX_MESSAGE_LENGTH
    const messageChunks = [];
    for (let i = 0; i < message.length; i += MAX_MESSAGE_LENGTH) {
      messageChunks.push(message.slice(i, i + MAX_MESSAGE_LENGTH));
    }

    // Handle case: Create room if needed
    if (isInitiatedRoom && initiateRoomId) {
      initiateRoom.mutate({
        userId: initiateRoomId,
        message: messageChunks[0], // Only send the first chunk when initiating a room
      });
      resetInput();
      return;
    }

    // Send each message chunk sequentially
    for (let i = 0; i < messageChunks.length; i++) {
      const isLastChunk = i === messageChunks.length - 1;
      const attach = isLastChunk ? attachmentsIds : [];
      await sendSingleMessage(messageChunks[i], attach);
    }

    resetInput();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    requestAnimationFrame(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    });
  };

  const handleEmojiSelect = (emoji: any) => {
    setMessage((prevMessage) => prevMessage + emoji.emoji);
  };

  const handleRemoveMedia = (mediaId: string) => {
    const updatedAttachments = attachments.filter((el) => el?.id !== mediaId);
    setAttachments(updatedAttachments);
  };

  return (
    <>
      {!!attachments.length && (
        <Box
          sx={{
            padding: '15px',
            display: 'flex',
            overflowX: 'auto',
            gap: '15px',
            minHeight: '120px',
            boxShadow: '0px 0px 6px 0px #B7BDC9',
          }}
        >
          {attachments?.map((file, index: number) => {
            const isImage = IMAGE_PATTERN.exec(file?.url);

            return (
              <Box
                sx={{ minWidth: isImage ? '100px' : '240px' }}
                key={file?.id}
              >
                <ImageContainer>
                  {isImage ? (
                    <StyledImage src={file?.url} alt={`media-${index}`} />
                  ) : (
                    <Box
                      sx={{ display: 'flex', gap: '10px', maxHeight: '92px' }}
                    >
                      <OtherFilePlaceholder fileName={file.name} size={92} />
                      <StyledTruncatedTypography
                        sx={{
                          maxWidth: '158px',
                          paddingRight: '20px',
                          whiteSpace: 'initial',
                        }}
                        variant={'poppins16Regular'}
                      >
                        {file.name}
                      </StyledTruncatedTypography>
                    </Box>
                  )}
                  <RemoveButton
                    onClick={() => handleRemoveMedia(file.id)}
                    sx={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </ImageContainer>
              </Box>
            );
          })}
        </Box>
      )}
      <Box
        sx={{
          padding: '20px 20px 30px 20px',
          boxShadow: '0px 0px 6px 0px #B7BDC9',
        }}
      >
        <Box sx={{ position: 'relative', width: '100%' }}>
          <StyledTextField
            fullWidth
            value={message}
            onKeyDown={handleKeyDown}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('common.writeMessage')}
            multiline
            slotProps={{
              htmlInput: {
                sx: {
                  paddingRight: '180px',
                  maxHeight: `${window.innerHeight * 0.2}px`,
                  minHeight: '30px',
                  paddingTop: '8px',
                },
              },
            }}
          />
          <BoxIcon sx={{ right: '20px', display: 'flex', gap: '5px' }}>
            <EmojiSection handleEmojiSelect={handleEmojiSelect} />
            {!isInitiatedRoom && (
              <AttachmentsInput
                onMediaChange={(attachments) => {
                  setAttachments(attachments);
                }}
                mediaFiles={attachments}
                disabled={false}
              />
            )}
            <IconButton onClick={handleSendMessage}>
              <SendMessageIcon />
            </IconButton>
          </BoxIcon>
        </Box>
      </Box>
    </>
  );
};

export default ChatInputSection;
