export const highlightSearchValue = (
  text: (string | React.ReactNode)[],
  searchValue: string
): React.ReactNode[] => {
  if (!searchValue.trim()) return text;

  return text.flatMap<React.ReactNode>((part, index) => {
    if (typeof part !== 'string') return [part];

    const regex = new RegExp(`(${searchValue})`, 'gi');
    const splitText = part.split(regex);

    return splitText.map<React.ReactNode>((segment, i) =>
      segment.toLowerCase() === searchValue.toLowerCase() ? (
        <span
          key={`${index}-${i}`}
          style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}
        >
          {segment}
        </span>
      ) : (
        segment
      )
    );
  });
};
