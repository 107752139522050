import {Box, Button, Link, Typography} from '@mui/material';
import FormInput from '@/components/forms/FormInput';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from '@/styled';
import LoginAsField from '@/components/forms/LoginAsField';
import { zodResolver } from '@hookform/resolvers/zod';
import validator from 'validator';
import { UserType } from '@/types/user';
import ReCAPTCHA from 'react-google-recaptcha';
import { registerUser } from '@/api/auth/fetchers';
import { useMutation } from '@tanstack/react-query';
import env from '@/env';
import { useAuthStore } from '@/store/authStore';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import FormConfirmPassword from '@/components/forms/FormConfirmPassword';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import i18n, { z } from '@/i18n';
import { PAGE_ROUTES } from '@/constants';

const siteKey = env.VITE_GCAPTHA_SITE_KEY;

export const firstRegistrationSchemaBase = z.object({
  role: z.enum(['Talent', 'Hirer'], {
    errorMap: () => ({ message: i18n.t('validation.roleRequired') }),
  }),
  displayName: z
    .string()
    .optional()
    .refine((value) => !value || /^[a-zA-Z0-9 _]{3,20}$/.test(value), {
      message: i18n.t('validation.invalidDisplayName', {
        min: 3,
        max: 20,
      }),
    }),
  email: z
    .string()
    .min(1, { message: i18n.t('validation.emailRequired') })
    .refine((value) => validator.isEmail(value), {
      message: i18n.t('validation.emailInvalid'),
    }),
  password: z
    .string()
    .min(8, { message: i18n.t('validation.passwordMinLength') })
    .refine((value) => /[A-Z]/.test(value), {
      message: i18n.t('validation.passwordUppercase'),
    })
    .refine((value) => /\d/.test(value), {
      message: i18n.t('validation.passwordNumber'),
    })
    .refine((value) => /[!@#$%^&*(),.?":{}|<>]/.test(value), {
      message: i18n.t('validation.passwordSpecialCharacter'),
    }),
  confirmPassword: z
    .string()
    .min(1, { message: i18n.t('validation.confirmPasswordRequired') }),
  gRecaptchaResponse: z
    .string()
    .min(1, { message: i18n.t('validation.recaptchaRequired') }),
});

export const firstRegistrationSchema = firstRegistrationSchemaBase.refine(
  (data) => data.password === data.confirmPassword,
  {
    path: ['confirmPassword'],
    message: i18n.t('validation.passwordsDoNotMatch'),
  }
);

export type FirstRegistrationFields = z.infer<typeof firstRegistrationSchema>;

const FirstRegistrationStep = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const setVerificationEmail = useAuthStore(
    (state) => state.setVerificationEmail
  );
  const authRole = useAuthStore((state) => state.role);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const setToken = useAuthStore((state) => state.setToken);

  const methods = useForm<FirstRegistrationFields>({
    resolver: zodResolver(firstRegistrationSchema),
    defaultValues: {
      role: authRole ?? 'Talent',
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { isDirty, errors },
  } = methods;

  const registrationMutation = useMutation({
    mutationFn: registerUser,
    onSuccess: (data, variables) => {
      if (data?.accessToken) {
        setToken(data?.accessToken);
        setVerificationEmail(variables?.email);
        navigate('/email-verification');
      }
    },
    onError: (error) => {
      try {
        const errorMessage = JSON.parse(error?.message)?.message;
        setError('email', { type: 'custom', message: errorMessage });
      } catch (e) {
        toast.error(t('errors.unknownError'));
      }
      // Reset ReCAPTCHA on error
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setValue('gRecaptchaResponse', '');
      }
    },
  });

  const setSelectedRole = useAuthStore((state) => state.setRole);

  const onRoleChange = (role: UserType['role']) => {
    setValue('role', role);
    setSelectedRole(role);
  };

  const role = watch('role');
  const confirmPassword = watch('confirmPassword');
  const email = watch('email');
  const gRecaptchaResponse = watch('gRecaptchaResponse');
  const onSubmit = (data: FirstRegistrationFields) => {
    const { confirmPassword, ...filteredData } = data; // Exclude confirmPassword
    registrationMutation.mutate(filteredData);
  };

  const isSubmitDisabled =
    !isDirty ||
    !!Object.keys(errors)?.length ||
    !confirmPassword ||
    !email ||
    !gRecaptchaResponse ||
    registrationMutation.isPending;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <LoginAsField
            value={role}
            onChange={onRoleChange}
            label={t('role.registerAs')}
          />
          <FormInput<FirstRegistrationFields>
            name="displayName"
            hint={t('validation.displayNameHint')}
            control={control}
            label={t('form.displayName')}
            placeholder={t('form.enterDisplayName')}
          />
          <FormInput<FirstRegistrationFields>
            name="email"
            control={control}
            label={t('form.email')}
            type="email"
            placeholder={t('form.enterEmail')}
          />
          <FormInput<FirstRegistrationFields>
            name="password"
            control={control}
            label={t('form.password')}
            type="password"
            placeholder={t('form.enterPassword')}
          />
          <FormConfirmPassword placeholder={t('form.enterConfirmPassword')} />
          <Controller
            name={'gRecaptchaResponse'}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={(value) => onChange(value)}
                  aria-label={t('form.recaptchaLabel')}
                />
              );
            }}
          />
          <Box sx={{ display: 'flex', gap: '10px', marginTop: '100px' }}>
            <Typography> {t('common.alreadyRegistered')}</Typography>
            <Link
              href={`/${PAGE_ROUTES.Login}`}
              sx={{ cursor: 'pointer', color: 'purple1' }}
            >
              {t('common.loginHere')}
            </Link>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '16px' }}
            disabled={isSubmitDisabled}
            type={'submit'}
          >
            {t('form.next')}
          </Button>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default FirstRegistrationStep;
