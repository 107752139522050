import { Avatar, Box, Typography } from '@mui/material';
import { stringAvatar } from '@/utils/getAvatarString.ts';
import dayjs from 'dayjs';
import { parseDescription } from '@/utils/parseDescription.tsx';
import { PhotoFileType } from '@/types/user.ts';
import {
  ImageContainer,
  StyledImage,
} from '@/pages/ChatsPage/components/ChatInputSection.tsx';
import { formatFileSize } from '@/utils/formatFileSizeToMb.ts';
import DownloadIcon from '@/assets/icons/download_icon.svg?react';
import OtherFilePlaceholder from '@/components/common/OtherFilePlaceholder';
import { useTranslation } from 'react-i18next';
import { IMAGE_PATTERN } from '@/constants';
import { useRoomsMessagesStore } from '@/store/roomsMessagesStore.ts';
import { highlightSearchValue } from '@/utils/highlightSearchValue.tsx';

type Props = {
  isMineMessage: boolean;
  name: string;
  message: string;
  date: Date;
  srcAvatar: string;
  attachments: PhotoFileType[];
};

const ChatMessageItem = ({
  isMineMessage,
  name,
  message,
  date,
  srcAvatar,
  attachments,
}: Props) => {
  const searchValue = useRoomsMessagesStore((state) => state.searchValue);
  const { t } = useTranslation();
  const parsedDescription = parseDescription(message);

  const formattedDate = dayjs(date).format('h:mm A');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: '15px',
        justifyContent: isMineMessage ? 'end' : 'start',
        flexDirection: isMineMessage ? 'row-reverse' : 'row',
      }}
    >
      <Avatar src={srcAvatar} {...stringAvatar(name)} />
      <Box
        sx={{
          padding: '15px',
          backgroundColor: !isMineMessage ? 'gray7' : 'gray8',
          borderRadius: '10px',
          maxWidth: '450px',
          ...(!isMineMessage && { borderTopLeftRadius: 0 }),
          ...(isMineMessage && { borderTopRightRadius: 0 }),
        }}
      >
        <Typography
          sx={{
            display: 'block',
            whiteSpace: 'pre-line',
            wordWrap: 'break-word',
          }}
          variant={'poppins16Medium'}
        >
          {highlightSearchValue(parsedDescription, searchValue)}
        </Typography>
        {!!attachments?.length && (
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              paddingTop: '15px',
            }}
          >
            {attachments?.map((el) => {
              const isImage = IMAGE_PATTERN.exec(el?.url);

              return (
                <Box key={el.url} sx={{ display: 'flex', gap: '16px' }}>
                  {isImage && (
                    <ImageContainer
                      sx={{ width: '60px', height: '60px', minHeight: '60px' }}
                    >
                      <StyledImage
                        sx={{ borderRadius: '3px' }}
                        src={el?.url}
                        alt={`media-${el}`}
                      />
                    </ImageContainer>
                  )}
                  {!isImage && (
                    <OtherFilePlaceholder fileName={el.name} size={60} />
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <Typography variant={'poppins16Regular'}>
                      {el.name}
                    </Typography>
                    <Typography
                      variant={'poppins14Regular'}
                      sx={{ opacity: 0.5 }}
                    >
                      {formatFileSize(el.size)}
                    </Typography>
                    <Box>
                      <a
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                        href={el.url}
                        download={el.name}
                      >
                        <DownloadIcon />
                        {t('common.download')}
                      </a>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
        <Typography
          sx={{ color: '#92979D', display: 'block', textAlign: 'right' }}
          variant={'poppins14Regular'}
        >
          {formattedDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatMessageItem;
