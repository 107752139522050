import { IDENTITY_VERIFICATION_STATUS } from '@/constants';
import { UserType } from '@/types/user.ts';

type VerificationParamType = UserType['verification'] | null | undefined;

export const isVerificationStatus = (
  currentStatus: VerificationParamType,
  targetStatus: UserType['verification']
): boolean => currentStatus === targetStatus;

const VerificationChecks = IDENTITY_VERIFICATION_STATUS.reduce(
  (acc, status) => ({
    ...acc,
    [`isVerification${status.charAt(0)}${status.slice(1).toLowerCase()}`]: (
      currentStatus: VerificationParamType
    ) => isVerificationStatus(currentStatus, status),
  }),
  {} as Record<
    `isVerification${Capitalize<Lowercase<UserType['verification']>>}`,
    (status: VerificationParamType) => boolean
  >
);

export const {
  isVerificationNew,
  isVerificationPending,
  isVerificationRejected,
  isVerificationApproved,
} = VerificationChecks;
