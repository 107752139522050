import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'sonner';
import { PAGE_ROUTES } from '@/constants';
import { useProfileStateSync } from '@/hooks/useProfileStateSync';
import { RoutesConfig } from '@/types/common';
import { lazy, Suspense } from 'react';
import { LayoutContainer } from '@/components/common/AuthLayout';
import { ProtectedRouteWrapper } from '@/components/common/ProtectedRouteWrapper';
import LoginPage from '@/pages/LoginPage';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import LandingPage from '@/pages/LandingPage';
import JobPostingPage from '@/pages/JobPostingPage';
import ProfilePage from '@/pages/ProfilePage';
import JobEditPage from '@/pages/JobEditPage';
import ExplorePage, { exploreRoutesConfig } from '@/pages/ExplorePage';
import JobDetailsPage from '@/pages/JobDetailsPage';
import OfferingDetailsPage from '@/pages/OfferingDetailsPage';
import OfferingCreationPage from '@/pages/OfferingCreationPage';
import OfferingEditPage from '@/pages/OfferingEditPage';
import MobileHeroAnimation from '@/components/common/MobileHeroAnimation';
import { useAuthStore } from '@/store/authStore';
import { useMediaQuery, useTheme } from '@mui/material';
import ChatsPage from '@/pages/ChatsPage';
import TalentDetailsPage from '@/pages/TalentDetailsPage';
import { isHirer, isTalent } from '@/utils/roleCheck.ts';
import IdentityVerificationPage from '@/pages/IdentityVerificationPage';
import { isVerificationApproved } from '@/utils/identityVerificationCheck.ts';

const RegistrationPage = lazy(() => import('@/pages/RegistrationPage'));
const EmailVerificationPage = lazy(
  () => import('@/pages/EmailVerificationPage')
);
const OnboardingPage = lazy(() => import('@/pages/OnboardingPage'));

const routesConfig: RoutesConfig = [
  {
    path: `/${PAGE_ROUTES.Registration}`,
    element: <RegistrationPage />,
    isProtected: true,
    accessCheck: (isAuth) => !isAuth,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.EmailVerification}`,
    element: <EmailVerificationPage />,
  },
  {
    path: `/${PAGE_ROUTES.ForgotPassword}`,
    element: <ForgotPasswordPage />,
  },
  {
    path: `/${PAGE_ROUTES.ResetPassword}`,
    element: <ResetPasswordPage />,
  },
  {
    path: `/${PAGE_ROUTES.Landing}`,
    element: <LandingPage />,
  },
  {
    path: `/${PAGE_ROUTES.Onboarding}`,
    element: <OnboardingPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !isProfileCompleted,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.IdentityVerification}`,
    element: <IdentityVerificationPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, _, verification) =>
      isAuth && isEmailVerified && !!isProfileCompleted && !isVerificationApproved(verification),
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.Login}`,
    element: <LoginPage />,
    isProtected: true,
    accessCheck: (isAuth) => !isAuth,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.JobPosting}`,
    element: <JobPostingPage />,
    isProtected: true,
    accessCheck: (
      isAuth,
      isEmailVerified,
      isProfileCompleted,
      role,
      verification
    ) =>
      isAuth &&
      isEmailVerified &&
      !!isProfileCompleted &&
      isHirer(role) &&
      isVerificationApproved(verification),
    redirectTo: `/${PAGE_ROUTES.IdentityVerification}`,
  },
  {
    path: `/${PAGE_ROUTES.JobEdit}/:id`,
    element: <JobEditPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, role) =>
      isAuth && isEmailVerified && !!isProfileCompleted && isHirer(role),
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.Profile}`,
    element: <ProfilePage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !!isProfileCompleted,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.Explore}`,
    element: <ExplorePage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !!isProfileCompleted,
    redirectTo: `/`,
    children: exploreRoutesConfig,
  },
  {
    path: `/${PAGE_ROUTES.JobDetails}/:id`,
    element: <JobDetailsPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, _, verification) =>
      isAuth &&
      isEmailVerified &&
      !!isProfileCompleted &&
      isVerificationApproved(verification),
    redirectTo: `/${PAGE_ROUTES.IdentityVerification}`,
  },
  {
    path: `/${PAGE_ROUTES.OfferingDetails}/:id`,
    element: <OfferingDetailsPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, _, verification) =>
      isAuth &&
      isEmailVerified &&
      !!isProfileCompleted &&
      isVerificationApproved(verification),
    redirectTo: `/${PAGE_ROUTES.IdentityVerification}`,
  },
  {
    path: `/${PAGE_ROUTES.OfferingCreation}`,
    element: <OfferingCreationPage />,
    isProtected: true,
    accessCheck: (
      isAuth,
      isEmailVerified,
      isProfileCompleted,
      role,
      verification
    ) =>
      isAuth &&
      isEmailVerified &&
      !!isProfileCompleted &&
      isTalent(role) &&
      isVerificationApproved(verification),
    redirectTo: `/${PAGE_ROUTES.IdentityVerification}`,
  },
  {
    path: `/${PAGE_ROUTES.OfferingEdit}/:id`,
    element: <OfferingEditPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, role) =>
      isAuth && isEmailVerified && !!isProfileCompleted && isTalent(role),
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.Chats}/:id?`,
    element: <ChatsPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !!isProfileCompleted,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.Talent}/:id`,
    element: <TalentDetailsPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, _, verification) =>
      isAuth &&
      isEmailVerified &&
      !!isProfileCompleted &&
      isVerificationApproved(verification),
    redirectTo: `/${PAGE_ROUTES.IdentityVerification}`,
  },
];

function App() {
  useProfileStateSync();
  const isAuth = useAuthStore((state) => state.isAuth);
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Suspense fallback={<LayoutContainer />}>
        <Routes>
          {routesConfig.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRouteWrapper
                  isProtected={route?.isProtected}
                  redirectTo={route?.redirectTo}
                  accessCheck={route?.accessCheck}
                >
                  {route.element}
                </ProtectedRouteWrapper>
              }
            >
              {route?.children?.map((childRoute) => (
                <Route
                  key={childRoute.path}
                  path={childRoute.path}
                  element={
                    <ProtectedRouteWrapper
                      isProtected={childRoute.isProtected}
                      redirectTo={childRoute.redirectTo}
                      accessCheck={childRoute.accessCheck}
                    >
                      {childRoute.element}
                    </ProtectedRouteWrapper>
                  }
                />
              ))}
            </Route>
          ))}
        </Routes>
        <Toaster richColors />
      </Suspense>
      {!isAuth && downMd && <MobileHeroAnimation />}
    </>
  );
}

export default App;
