import { Box, Button, Typography } from '@mui/material';
import EmptyMessagesIcon from '@/assets/icons/empty_messages_icon.svg?react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@/store/authStore.ts';
import { isHirer, isTalent } from '@/utils/roleCheck.ts';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';

const EmptyMessages = ({ isEmptyRooms }: { isEmptyRooms: boolean }) => {
  const { t } = useTranslation();
  const role = useAuthStore((state) => state.role);
  const navigate = useNavigate();
  const onExploreClick = () => {
    if (isHirer(role)) {
      navigate(`/${PAGE_ROUTES.Explore}/${PAGE_ROUTES.ExploreOfferings}`);
    }

    if (isTalent(role)) {
      navigate(`/${PAGE_ROUTES.Explore}/${PAGE_ROUTES.ExploreJobs}`);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <EmptyMessagesIcon />
      <Typography sx={{ marginTop: '18px' }} variant={'poppins24Medium'}>
        {t('common.noMessages')}
      </Typography>
      <Typography sx={{ marginTop: '8px' }} variant={'poppins16Regular'}>
        {t('common.writeFirstMessage')}
      </Typography>
      {isEmptyRooms && (
        <Button
          onClick={onExploreClick}
          variant={'contained'}
          sx={{ marginTop: '18px', maxWidth: '300px', width: '100%' }}
        >
          {isHirer(role) && t('landingPage.exploreOfferings')}
          {isTalent(role) && t('landingPage.exploreJobs')}
        </Button>
      )}
    </Box>
  );
};

export default EmptyMessages;
