import { create } from 'zustand';

interface ChatDrawerState {
  open: boolean;
  toggle: (newOpen?: boolean) => void;
}

export const useChatDrawerStore = create<ChatDrawerState>((set) => ({
  open: false,
  toggle: (newOpen) => set((state) => ({ open: newOpen ?? !state.open })),
}));
