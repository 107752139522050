import {
  Avatar,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { stringAvatar } from '@/utils/getAvatarString.ts';
import PhoneIcon from '@/assets/icons/phone_call_icon.svg?react';
import VideoIcon from '@/assets/icons/video_call_icon.svg?react';
import MoreIcon from '@/assets/icons/more_options_icon.svg?react';

type Props = {
  name: string;
  srcAvatar?: string;
};

const ChatHeader = ({ name, srcAvatar }: Props) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        padding: '20px 20px 30px 20px',
        boxShadow: '0px 4px 3px 0px #0000000D',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        <Avatar src={srcAvatar} {...stringAvatar(name, 60)} />
        <Typography variant={'poppins18Medium'}>{name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        {!downMd && (
          <>
            <IconButton>
              <PhoneIcon />
            </IconButton>
            <IconButton>
              <VideoIcon />
            </IconButton>
          </>
        )}
        <IconButton>
          <MoreIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatHeader;
