import AuthLayout from '@/components/common/AuthLayout';
import { FormCardBox } from '@/styled';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useAuthStore } from '@/store/authStore.ts';
import { createVerificationSession } from '@/api/user/fetchers.ts';
import env from '@/env.ts';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import {
  isVerificationPending,
  isVerificationRejected,
} from '@/utils/identityVerificationCheck.ts';
import { useUserProfile } from '@/api/user/queries.ts';

const IdentityVerificationPage = () => {
  const navigate = useNavigate();
  const setIsIdentityVerificationPageVisited = useAuthStore(
    (state) => state.setIsIdentityVerificationPageVisited
  );

  const verification = useAuthStore((state) => state.verification);
  const isAuth = useAuthStore((state) => state.isAuth);
  const { refetch: refetchProfile } = useUserProfile(isAuth);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const isPending = isVerificationPending(verification);
  const isRejected = isVerificationRejected(verification);

  const handleVerifyIdentity = async () => {
    setIsLoading(true);

    try {
      const stripePromise = loadStripe(env.VITE_STRIPE_PUBLISHABLE_KEY);

      // Call your backend to create a verification session
      const response = await createVerificationSession();

      if (!response?.client_secret) {
        throw new Error('Failed to retrieve client secret.');
      }

      const stripe = await stripePromise;

      // Redirect to the Stripe-hosted verification flow
      const result = await stripe?.verifyIdentity(response.client_secret);

      if (result?.error) {
        toast.error(t('idVerification.rejectedVerification'));
      } else {
        toast.success(t('idVerification.pendingVerification'));
      }
    } catch (error) {
      toast.error(t('idVerification.failedVerification'));
    } finally {
      refetchProfile();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsIdentityVerificationPageVisited(true);
  }, []);
  return (
    <AuthLayout>
      <FormCardBox>
        <Box sx={{ display: 'flex', gap: '6px' }}>
          <Typography variant="poppins24Bold">
            {t('common.verificationStep')}
          </Typography>
        </Box>
        <Typography
          sx={{ display: 'block', marginTop: '8px' }}
          variant={'poppins24Regular'}
        >
          {!isPending && !isRejected && t('common.uploadPhotoId')}
          {isPending && t('idVerification.pendingVerification')}
          {isRejected && t('idVerification.rejectedVerification')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            marginTop: '45px',
            flexDirection: 'column',
          }}
        >
          {!isPending && (
            <Button
              sx={{ flex: 1 }}
              disabled={isLoading}
              variant={'contained'}
              onClick={() => {
                handleVerifyIdentity();
              }}
            >
              {t('common.startVerification')}
            </Button>
          )}
          {!isPending && (
            <Button
              sx={{ flex: 1 }}
              onClick={() => {
                navigate('/');
              }}
              variant={'outlined'}
            >
              {t('identityUpload.uploadLater')}
            </Button>
          )}
          {isPending && (
            <Button
              sx={{ flex: 1 }}
              onClick={() => {
                navigate('/');
              }}
              variant={'outlined'}
            >
              {t('form.back')}
            </Button>
          )}
        </Box>
      </FormCardBox>
    </AuthLayout>
  );
};

export default IdentityVerificationPage;
