import Layout from '@/components/common/Layout';
import Grid from '@mui/material/Grid2';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import RoomSearch from '@/pages/ChatsPage/components/RoomSearch.tsx';
import RoomsList from '@/pages/ChatsPage/components/RoomsList.tsx';
import ChatSection from '@/pages/ChatsPage/components/ChatSection.tsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRoomsQuery, useSearchRoomsQuery } from '@/api/chat/queries.ts';
import { useMemo } from 'react';
import { useChatDrawerStore } from '@/store/toggleChatDrawerStore.ts';
import { useRoomsMessagesStore } from '@/store/roomsMessagesStore.ts';
import { isSearchedRoom } from '@/utils/roomsCheckHelper.ts';

const ChatsPage = () => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  const { toggle: toggleDrawer, open } = useChatDrawerStore();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const searchValue = useRoomsMessagesStore((state) => state.searchValue);
  const cursor = searchParams.get('c');
  const isSearchMode = !!searchValue;

  const {
    data: roomsPages,
    fetchNextPage,
    isLoading: isRoomsLoading,
    hasNextPage,
  } = useRoomsQuery();

  const {
    data: searchRoomsPages,
    fetchNextPage: fetchNextSearchPage,
    isLoading: isSearchedRoomsLoading,
    hasNextPage: hasSearchNextPage,
  } = useSearchRoomsQuery(searchValue ?? '');

  const rooms = useMemo(
    () =>
      roomsPages?.pages.flatMap((page) => page?.data).filter((el) => !!el) ||
      [],
    [roomsPages]
  );

  const searchedRooms = useMemo(
    () =>
      searchRoomsPages?.pages
        .flatMap((page) => page?.data)
        .filter((el) => !!el) || [],
    [searchRoomsPages]
  );

  const {
    currentRooms,
    isLoading: isCurrentLoading,
    fetchNextPage: fetchCurrentNextPage,
    hasNextPage: hasCurrentNextPage,
  } = useMemo(() => {
    if (!isSearchMode) {
      return {
        currentRooms: rooms,
        isLoading: isRoomsLoading,
        fetchNextPage,
        hasNextPage,
      };
    } else {
      return {
        currentRooms: searchedRooms,
        isLoading: isSearchedRoomsLoading,
        fetchNextPage: fetchNextSearchPage,
        hasNextPage: hasSearchNextPage,
      };
    }
  }, [
    searchedRooms,
    rooms,
    isRoomsLoading,
    isSearchedRoomsLoading,
    fetchNextSearchPage,
    fetchNextPage,
    hasNextPage,
    hasSearchNextPage,
  ]);

  const firstRoomId = currentRooms?.[0]?.id ?? '';

  const selectedRoom = useMemo(() => {
    if (isSearchMode) {
      return currentRooms?.find((el) =>
        cursor && isSearchedRoom(el)
          ? el?.message?.cursor === cursor
          : el?.id === firstRoomId
      );
    } else {
      return currentRooms?.find((el) => el?.id === (id ?? firstRoomId));
    }
  }, [id, currentRooms, cursor, isSearchMode, firstRoomId]);

  const searchWithRoomsList = (
    <>
      <RoomSearch />
      <RoomsList
        selectedRoomCursor={
          selectedRoom && isSearchedRoom(selectedRoom)
            ? selectedRoom?.message?.cursor
            : undefined
        }
        rooms={currentRooms}
        fetchNextPage={fetchCurrentNextPage}
        hasNextPage={hasCurrentNextPage}
        isLoading={isCurrentLoading}
      />
    </>
  );

  return (
    <Layout
      scrollProviderSx={{ flex: 1 }}
      contentContainerSx={{ padding: { xs: 0, md: '0 40px' } }}
      showBottomSpace={!downMd}
    >
      <Grid
        container
        spacing={'20px'}
        sx={{
          marginTop: '40px',
          height: { md: 'calc(100vh - 220px)', xs: 'calc(100% - 40px)' },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!downMd && (
          <Grid
            size={{ xs: 12, md: 3 }}
            sx={{ overflow: 'auto', height: '100%' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
                overflow: 'auto',
                height: '100%',
              }}
            >
              {searchWithRoomsList}
            </Box>
          </Grid>
        )}
        <Grid size={{ xs: 12, md: 9 }} sx={{ height: '100%' }}>
          <ChatSection selectedRoom={selectedRoom} />
        </Grid>
        {downMd && (
          <Drawer
            anchor={'bottom'}
            PaperProps={{
              sx: {
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              },
            }}
            open={open}
            onClose={() => toggleDrawer(false)}
          >
            {searchWithRoomsList}
          </Drawer>
        )}
      </Grid>
    </Layout>
  );
};

export default ChatsPage;
