import ProfileWrapper from '@/components/common/ProfileWrapper';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { StyledTruncatedTypography } from '@/styled';
import LocationIcon from '@/assets/icons/location_icon.svg?react';
import { LocationType } from '@/types/user';
import { getLocationString } from '@/utils/getLocationString';
import { PublicUserType, JobDetailsType } from '@/api/jobPosting/types';
import { getJobTypeLabel } from '@/utils/getLabels';
import { stringAvatar } from '@/utils/getAvatarString';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/formatCurrency';
import { useAuthStore } from '@/store/authStore.ts';
import { isTalent } from '@/utils/roleCheck.ts';
import { PAGE_ROUTES } from '@/constants';
import { userInitiateRoomUserStore } from '@/store/initiateRoomUserStore.ts';
import { useNavigate } from 'react-router-dom';

type Props = {
  hirer: PublicUserType;
  location: LocationType;
  price: JobDetailsType['budget'];
  type: JobDetailsType['type'];
};

const JobDetailsProfile = ({ hirer, location, price, type }: Props) => {
  const role = useAuthStore((state) => state.role);
  const setUser = userInitiateRoomUserStore((state) => state.setUser);

  const { t } = useTranslation();
  const parsedLocation = getLocationString(location);
  const name = hirer?.displayName ?? '';
    const navigate = useNavigate();

    const initiateRoom = () => {
        if (!hirer.roomId) {
            setUser(hirer);
            navigate(`/${PAGE_ROUTES.Chats}`);
        } else {
            navigate(`/${PAGE_ROUTES.Chats}/${hirer.roomId}`);
        }
    };
  return (
    <ProfileWrapper>
      <Avatar src={hirer?.photo?.url} {...stringAvatar(name, 180)} />
      <StyledTruncatedTypography
        sx={{ display: 'block', marginTop: '22px' }}
        variant={'poppins30Semibold'}
      >
        {name}
      </StyledTruncatedTypography>
      <Box sx={{ display: 'flex', gap: '3px', marginTop: '16px' }}>
        <LocationIcon style={{ width: '22px', height: '22px' }} />
        <Typography variant={'poppins16Medium'}>{parsedLocation}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '3px',
          alignItems: 'center',
          marginTop: '22px',
        }}
      >
        <Typography variant={'poppins20Semibold'} sx={{ color: 'purple3' }}>
          {getJobTypeLabel(type, t)}
          {price ? `: ${formatCurrency(price)}$` : ''}
        </Typography>
      </Box>
      {isTalent(role) && (
        <Button
          onClick={initiateRoom}
          sx={{ marginTop: '22px', width: '100%' }}
          variant={'contained'}
        >
          {t('common.message')}
        </Button>
      )}
    </ProfileWrapper>
  );
};

export default JobDetailsProfile;
