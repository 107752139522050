import { Avatar, Box, Tooltip } from '@mui/material';
import { stringAvatar } from '@/utils/getAvatarString.ts';
import { UserType } from '@/types/user.ts';
import VerificationIcon from '@/assets/icons/verification_icon.svg?react';
import { useTranslation } from 'react-i18next';

type Props = {
  verified: UserType['verified'];
  name: string;
  avatarSrc: string;
};

const ProfileAvatar = ({ verified, name, avatarSrc }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ position: 'relative' }}>
      <Avatar src={avatarSrc} {...stringAvatar(name, 180)} />
      {verified && (
        <Tooltip placement={'right'} title={t('common.verified')}>
          <VerificationIcon
            style={{ position: 'absolute', right: 5, bottom: 5 }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default ProfileAvatar;
