import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/store/authStore';
import { useUserProfile } from '@/api/user/queries';
import { PAGE_ROUTES } from '@/constants';
import {
  isVerificationApproved,
} from '@/utils/identityVerificationCheck.ts';

export function useProfileStateSync() {
  const navigate = useNavigate();
  const isAuth = useAuthStore((state) => state.isAuth);
  const isIdentityVerificationPageVisited = useAuthStore(
    (state) => state.isIdentityVerificationPageVisited
  );
  const setSelectedRole = useAuthStore((state) => state.setRole);

  const currentProfileCompleted = useAuthStore(
    (state) => state.isProfileCompleted
  );
  const currentEmailVerified = useAuthStore((state) => state.isEmailVerified);
  const currentVerified = useAuthStore((state) => state.verification);
  const setProfileState = useAuthStore((state) => state.setProfileState);
  const setVerificationEmail = useAuthStore(
    (state) => state.setVerificationEmail
  );
  const { data } = useUserProfile(isAuth, () =>
    navigate(`${PAGE_ROUTES.Login}`)
  );

  const isEmailVerified = data?.emailVerified;
  const isProfileCompleted = data?.completed;
  const verification = data?.verification;

  useEffect(() => {
    if (!data || !isAuth) return;

    if (
      isEmailVerified !== currentEmailVerified ||
      isProfileCompleted !== currentProfileCompleted ||
        verification !== currentVerified
    ) {
      setProfileState({
        isEmailVerified: isEmailVerified ?? currentEmailVerified,
        isProfileCompleted: isProfileCompleted ?? currentProfileCompleted,
        verification: verification ?? currentVerified,
      });
    }

    setSelectedRole(data?.role);
    // Redirect based on profile completion and email verification
    if (!isEmailVerified) {
      setVerificationEmail(data?.email);
      navigate(`/${PAGE_ROUTES.EmailVerification}`);
    } else if (!isProfileCompleted) {
      navigate(`/${PAGE_ROUTES.Onboarding}`);
    } else if (
      !isVerificationApproved(data?.verification) &&
      !isIdentityVerificationPageVisited
    ) {
      navigate(`/${PAGE_ROUTES.IdentityVerification}`);
    }
  }, [
    data,
    isEmailVerified,
    isProfileCompleted,
    currentEmailVerified,
    currentProfileCompleted,
    currentVerified,
    verification,
    setProfileState,
    navigate,
  ]);
}
