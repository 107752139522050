import { Box, styled } from '@mui/material';
import ChatHeader from '@/pages/ChatsPage/components/ChatHeader.tsx';
import ChatMessages from '@/pages/ChatsPage/components/ChatMessages.tsx';
import ChatInputSection from '@/pages/ChatsPage/components/ChatInputSection.tsx';
import { userInitiateRoomUserStore } from '@/store/initiateRoomUserStore.ts';
import { useParams } from 'react-router-dom';
import { useAuthStore } from '@/store/authStore.ts';
import { isHirer } from '@/utils/roleCheck.ts';
import { useCallback, useMemo, useRef } from 'react';
import { useRoomsMessagesStore } from '@/store/roomsMessagesStore.ts';
import {
  MessageWithAttachments,
  RoomType,
  SearchedRoomType,
} from '@/api/chat/types.ts';
import { isSearchedRoom } from '@/utils/roomsCheckHelper.ts';

const ScrollableChatContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  padding: '30px 20px',
  gap: '30px',
}));

type Props = {
  selectedRoom?: RoomType | SearchedRoomType;
};

const ChatSection = ({ selectedRoom }: Props) => {
  const roomsData = useRoomsMessagesStore((state) => state.roomsData);
  const searchValue = useRoomsMessagesStore((state) => state.searchValue);
  const isSearchMode = !!searchValue;
  const setRoomsData = useRoomsMessagesStore((state) => state.setRoomsData);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const initiatedUserRoom = userInitiateRoomUserStore((state) => state.user);
  const { id } = useParams();
  const role = useAuthStore((state) => state.role);

  const roomId = selectedRoom?.id ?? '';
  const isInitiatedRoom = !id && initiatedUserRoom;

  const roomUserToDisplay = isHirer(role)
    ? selectedRoom?.talent
    : selectedRoom?.hirer;

  const currentSelectedUser = isInitiatedRoom
    ? initiatedUserRoom
    : roomUserToDisplay;

  const isEmptyRooms = !selectedRoom && !isInitiatedRoom;

  const setNewMessages = useCallback(
    (message: MessageWithAttachments) => {
      setRoomsData(roomId, {
        messages: [...(roomsData?.[roomId]?.messages || []), message],
      });
    },
    [roomId, roomsData?.[roomId]]
  );

  const stateRoomId = useMemo(() => {
    if (isSearchMode && selectedRoom && isSearchedRoom(selectedRoom)) {
      // If search mode -> state room id is cursor
      return selectedRoom?.message?.cursor;
    } else {
      // If not search mode -> state room id is room.id
      return roomId;
    }
  }, [isSearchMode, selectedRoom, roomId]);

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!isEmptyRooms && currentSelectedUser && (
        <ChatHeader
          name={currentSelectedUser?.displayName ?? ''}
          srcAvatar={currentSelectedUser?.photo?.url}
        />
      )}
      <ScrollableChatContainer ref={chatContainerRef}>
        <ChatMessages
          searchedMessage={
            selectedRoom && isSearchedRoom(selectedRoom)
              ? selectedRoom?.message
              : undefined
          }
          isInitiatedRoom={isInitiatedRoom}
          roomId={roomId}
          isEmptyRooms={isEmptyRooms}
          currentSelectedUser={currentSelectedUser}
          chatContainerRef={chatContainerRef}
          roomData={roomsData[stateRoomId]}
        />
      </ScrollableChatContainer>
      {!isEmptyRooms && !isSearchMode && (
        <ChatInputSection
          isInitiatedRoom={isInitiatedRoom}
          initiateRoomId={initiatedUserRoom?.id}
          roomId={roomId}
          chatContainerRef={chatContainerRef}
          setNewMessage={setNewMessages}
        />
      )}
    </Box>
  );
};

export default ChatSection;
