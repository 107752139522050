import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import RoomListItem from '@/pages/ChatsPage/components/RoomListItem.tsx';
import { userInitiateRoomUserStore } from '@/store/initiateRoomUserStore.ts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import { memo } from 'react';
import { StyledProgressLoader } from '@/styled';
import EmptyRoomsIcon from '@/assets/icons/empty_rooms_icon.svg?react';
import { useTranslation } from 'react-i18next';
import { RoomType, SearchedRoomType } from '@/api/chat/types.ts';
import { useAuthStore } from '@/store/authStore.ts';
import { isHirer } from '@/utils/roleCheck.ts';
import { useChatDrawerStore } from '@/store/toggleChatDrawerStore.ts';
import { useRoomsMessagesStore } from '@/store/roomsMessagesStore.ts';
import { isRoom, isSearchedRoom } from '@/utils/roomsCheckHelper.ts';

type Props = {
  rooms: RoomType[] | SearchedRoomType[];
  selectedRoomCursor?: SearchedRoomType['message']['cursor'];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
};

const RoomsList = memo(
  ({
    selectedRoomCursor,
    rooms,
    fetchNextPage,
    hasNextPage,
    isLoading,
  }: Props) => {
    const location = useLocation();
    const searchValue = useRoomsMessagesStore((state) => state.searchValue);
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down('md'));
    const toggleDrawer = useChatDrawerStore((state) => state.toggle);
    const { t } = useTranslation();
    const role = useAuthStore((state) => state.role);
    const initiatedUserRoom = userInitiateRoomUserStore((state) => state.user);
    const { id } = useParams();
    const navigate = useNavigate();
    const shouldActivateFirstRoom = !id && !initiatedUserRoom;

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;
      if (
        hasNextPage &&
        target.scrollHeight - target.scrollTop === target.clientHeight
      ) {
        fetchNextPage();
      }
    };

    const toggleDrawerForMobile = () => {
      if (downMd) {
        toggleDrawer(false);
      }
    };

    const emptyRooms = !rooms.length;

    const handleRoomClick = (room: RoomType | SearchedRoomType) => {
      toggleDrawerForMobile();
      const existingSearchParams = new URLSearchParams(location.search);

      const newSearchParams = new URLSearchParams();
      if (!!searchValue && isSearchedRoom(room)) {
        newSearchParams.set('c', room?.message?.cursor);

        existingSearchParams.forEach((value, key) => {
          if (!newSearchParams.has(key)) {
            newSearchParams.set(key, value);
          }
        });
      }
      navigate({
        pathname: `/${PAGE_ROUTES.Chats}/${room.id}`,
        search: newSearchParams.toString(),
      });
    };

    return (
      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          background: (theme) => theme.palette.gradient7,
          borderRadius: '10px',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
        onScroll={handleScroll}
      >
        <>
          {initiatedUserRoom && (
            <RoomListItem
              onClick={() => {
                toggleDrawerForMobile();
                navigate(`/${PAGE_ROUTES.Chats}`);
              }}
              isActive={!id}
              name={initiatedUserRoom.displayName}
              avatarSrc={initiatedUserRoom.photo?.url}
            />
          )}
          {!initiatedUserRoom && emptyRooms && !isLoading && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyRoomsIcon />
              <Typography variant={'poppins24Medium'}>
                {t('common.emptyFolder')}
              </Typography>
              <Typography variant={'poppins16Regular'}>
                {t('common.noContacts')}
              </Typography>
            </Box>
          )}
          {rooms.map((room, index) => {
            const isFirstActive = shouldActivateFirstRoom && index === 0;
            const isSearchMode = !!searchValue;
            const isSearched = isSearchedRoom(room);
            const isRegularRoom = isRoom(room);
            const roomCursorId = isSearched ? room.message?.cursor : undefined;

            // Determine if the room should be active
            let isActive = false;
            if (isFirstActive) {
              isActive = true;
            } else if (isSearchMode) {
              isActive = selectedRoomCursor === roomCursorId;
            } else {
              isActive = room.id === id;
            }

            // Get the correct last message
            let lastMessage = '';
            if (isSearchMode && isSearched) {
              lastMessage = room.message?.message ?? '';
            } else if (isRegularRoom) {
              lastMessage = room.lastMessage?.message ?? '';
            }

            return (
              <RoomListItem
                key={room.id + (isSearched ? (room.message?.cursor ?? '') : '')}
                id={room.id}
                onClick={() => handleRoomClick(room)}
                isActive={isActive}
                name={
                  isHirer(role)
                    ? room.talent.displayName
                    : room.hirer.displayName
                }
                lastSeenDate={room.lastMessageAt}
                lastMessage={lastMessage}
                avatarSrc={
                  isHirer(role)
                    ? room.talent?.photo?.url
                    : room.hirer?.photo?.url
                }
              />
            );
          })}
        </>
        {isLoading && (
          <StyledProgressLoader
            sx={{
              paddingTop: '10px',
              transform: 'initial',
              position: 'initial',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        )}
      </Box>
    );
  }
);

export default RoomsList;
