import { Avatar, Box, MenuItem, styled, Typography } from '@mui/material';
import { stringAvatar } from '@/utils/getAvatarString';
import { useUserProfile } from '@/api/user/queries';
import { StyledMenu } from '@/styled';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import { useAuthStore } from '@/store/authStore';
import { useTranslation } from 'react-i18next';
import { isHirer, isTalent } from '@/utils/roleCheck';
import { UserType } from '@/types/user.ts';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: 0,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ProfileBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  padding: '20px',
}));

const StyledMenuSx = {
  '& .MuiPaper-root': {
    minWidth: '208px',
    backgroundColor: 'gradient9',
    '& .MuiList-root': {
      padding: 0,
    },
  },
};

type MenuItemType = {
  label: string;
  route: string;
  action?: () => void;
};

const getMenuItems = (
  role: UserType['role'] | null,
  t: (key: string) => string,
  navigate: (route: string) => void,
  logout: () => void,
  isVerified: boolean
): MenuItemType[] => {
  const verifyIdentityItem = !isVerified
    ? [
        {
          label: t('idVerification.idVerification'),
          route: `/${PAGE_ROUTES.IdentityVerification}`,
        },
      ]
    : [];
  if (isHirer(role)) {
    return [
      {
        label: t('common.profile'),
        route: `/${PAGE_ROUTES.Profile}`,
      },
      {
        label: t('common.savedProfiles'),
        route: `/${PAGE_ROUTES.SavedProfiles}`,
      },
      {
        label: t('common.savedOfferings'),
        route: `/${PAGE_ROUTES.SavedOfferings}`,
      },
      ...verifyIdentityItem,
      {
        label: t('common.logout'),
        action: () => {
          logout();
          navigate('/');
        },
        route: `/`,
      },
    ];
  }

  if (isTalent(role)) {
    return [
      {
        label: t('common.profile'),
        route: `/${PAGE_ROUTES.Profile}`,
      },
      {
        label: t('common.savedJobs'),
        route: `/${PAGE_ROUTES.SavedJobs}`,
      },
      {
        label: t('common.savedOfferings'),
        route: `/${PAGE_ROUTES.SavedOfferings}`,
      },
      ...verifyIdentityItem,
      {
        label: t('common.logout'),
        action: () => {
          logout();
          navigate('/');
        },
        route: `/`,
      },
    ];
  }

  return [];
};

const AvatarDropdown = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const role = useAuthStore((state) => state.role);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { data: profile } = useUserProfile(true);
  const userName = profile?.displayName ?? '';
  const src = profile?.photo?.url;
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);

  const menuItems = getMenuItems(
    role,
    t,
    navigate,
    logout,
    !!profile?.verified
  );

  return (
    <>
      <Avatar
        onClick={handleOpenMenu}
        src={src}
        {...stringAvatar(userName, 60, { cursor: 'pointer' })}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={StyledMenuSx}
      >
        {menuItems.map((item) => (
          <StyledMenuItem
            key={item.label}
            onClick={() => {
              if (item.action) {
                item.action();
              } else {
                navigate(item.route);
              }
              handleCloseMenu();
            }}
          >
            <ProfileBox>
              <Typography variant={'poppins18Medium'}>{item.label}</Typography>
            </ProfileBox>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default AvatarDropdown;
