import { StyledInput } from '@/styled';
import { useTranslation } from 'react-i18next';
import { Box, debounce } from '@mui/material';
import { BoxIcon } from '@/components/forms/FormSocialLinks';
import SearchIcon from '@/assets/icons/search_icon.svg?react';
import { useEffect, useMemo, useState } from 'react';
import { useRoomsMessagesStore } from '@/store/roomsMessagesStore.ts';
import { useSearchParams } from 'react-router-dom';
import CrossIcon from '@/assets/icons/cross_icon.svg?react';

const RoomSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');

  const setSearchValue = useRoomsMessagesStore((state) => state.setSearchValue);
  const searchValue = useRoomsMessagesStore((state) => state.searchValue);

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(searchQuery);

  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500
      ),
    []
  );

  useEffect(() => {
    setSearchValue(searchQuery ?? '');
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (searchValue) {
      newSearchParams.set('search', searchValue);
    } else {
      newSearchParams.delete('search');
      newSearchParams.delete('c');
    }
    setSearchParams(newSearchParams);
  }, [searchValue]);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <StyledInput
        inputProps={{
          style: { paddingLeft: '56px', paddingRight: '48px' },
        }}
        value={inputValue}
        onChange={(e) => {
          const newValue = e.target.value;
          setInputValue(e.target.value);
          handleDebouncedValue(newValue, setSearchValue);
        }}
        sx={{ width: '100%' }}
        placeholder={t('common.search')}
      />
      <BoxIcon sx={{ left: '20px' }}>
        <SearchIcon
          style={{ color: '#92979D', width: '24px', height: '24px' }}
        />
      </BoxIcon>
      {inputValue && (
        <BoxIcon
          onClick={() => {
            setInputValue('');
            handleDebouncedValue('', setSearchValue);
          }}
          sx={{ right: '20px', cursor: 'pointer' }}
        >
          <CrossIcon
            style={{ color: '#92979D', width: '14px', height: '24px' }}
          />
        </BoxIcon>
      )}
    </Box>
  );
};

export default RoomSearch;
