import GradientCard from '@/components/common/GradientCard';
import { Box, Typography } from '@mui/material';
import FavoriteButton from '@/components/common/FavoriteButton';
import DollarSignIcon from '@/assets/icons/dolar_sign_icon.svg?react';
import JobFilterBadge from '@/pages/ProfilePage/components/hirer/JobFilterBadge.tsx';
import EditButton from '@/components/common/EditButton';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import { memo, useState } from 'react';
import { OfferingType } from '@/api/offering/types';
import { getOfferingTypeLabel } from '@/utils/getLabels';
import { useTranslation } from 'react-i18next';
import RemoveButton from '@/components/common/RemoveButton';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { handleMutationError } from '@/utils/handleMutationError';
import {
  deleteOffering,
  saveOfferingToFavorites,
} from '@/api/offering/fetchers';
import { formatCurrency } from '@/utils/formatCurrency';
import { parseDescription } from '@/utils/parseDescription.tsx';
import { Carousel } from 'react-responsive-carousel';
import { PhotoFileType } from '@/types/user';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MoreOfferings from '@/pages/OfferingDetailsPage/components/MoreOfferings.tsx';

type Props = {
  type: OfferingType['type'];
  talentId: string;
  price: OfferingType['price'];
  medias: PhotoFileType[];
  skills: OfferingType['skills'];
  description: OfferingType['description'];
  title: OfferingType['title'];
  isMyOffer: boolean;
  isFavorite: boolean;
};

const OfferingDetailsSection = memo(
  ({
    talentId,
    type,
    price,
    isFavorite,
    medias,
    skills,
    description,
    title,
    isMyOffer,
  }: Props) => {
    const queryClient = useQueryClient();
    const parsedDescription = parseDescription(description);

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const deleteOfferingMutation = useMutation({
      mutationFn: deleteOffering,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['offerings'] });

        toast.success(t('form.offeringDeleted'));
        handleCloseConfirmation();
      },
      onError: (error) => {
        handleMutationError(error);
        handleCloseConfirmation();
      },
    });

    const saveToFavoriteMutation = useMutation({
      mutationFn: saveOfferingToFavorites,
      onSuccess: (data) => {
        queryClient.setQueryData(['offering', id], (oldData: any) => ({
          ...oldData,
          isFavorite: data?.isFavorite,
        }));
        toast.success(isFavorite ? t('common.removed') : t('common.saved'));
      },
      onError: (error) => {
        handleMutationError(error);
      },
    });

    const handleDelete = () => {
      if (id) {
        deleteOfferingMutation.mutate(id);
        navigate(`/${PAGE_ROUTES.Explore}/${PAGE_ROUTES.ExploreOfferings}`);
      }
    };

    return (
      <GradientCard>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '15px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '38px' },
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
            {!isMyOffer && (
              <FavoriteButton
                onClick={() => {
                  if (id) {
                    saveToFavoriteMutation.mutate(id);
                  }
                }}
                isFavorite={isFavorite}
              />
            )}
            {isMyOffer && (
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <EditButton
                  onClick={() => {
                    navigate(`/${PAGE_ROUTES.OfferingEdit}/${id}`);
                  }}
                  iconSize={22}
                  sx={{
                    minWidth: '48px',
                    height: '48px',
                    borderRadius: '99px',
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '3px',
              alignItems: 'center',
              marginY: '16px',
            }}
          >
            <DollarSignIcon style={{ width: '25px', height: '25px' }} />
            <Typography variant={'poppins18Regular'}>
              {getOfferingTypeLabel(type, t) || ''}
              {price ? `: ${formatCurrency(price)}$` : ''}
            </Typography>
          </Box>
          {!!medias.length && (
            <Carousel emulateTouch>
              {medias.map((photo) => (
                <Box
                  key={photo.url}
                  component="img"
                  sx={{
                    width: '100%',
                    height: '230px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    opacity: photo.url ? 1 : 0,
                  }}
                  alt={photo.description ?? t('common.offeringAlt')}
                  src={photo.url}
                />
              ))}
            </Carousel>
          )}
          <Typography
            sx={{ marginTop: '15px', marginBottom: '5px', display: 'block' }}
            variant={'poppins20Semibold'}
          >
            {t('form.description')}
          </Typography>
          <Typography
            variant={'poppins16Regular'}
            sx={{ whiteSpace: 'pre-line' }}
          >
            {parsedDescription}
          </Typography>
          <Typography
            sx={{ display: 'block', marginTop: '44px', marginBottom: '5px' }}
            variant={'poppins20Semibold'}
          >
            {t('form.skills')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
              paddingRight: '48px',
            }}
          >
            {skills?.map((el) => (
              <JobFilterBadge
                sxTypo={{ fontWeight: 500, fontSize: '16px' }}
                key={el}
                title={el}
              />
            ))}
          </Box>
          {isMyOffer && (
            <RemoveButton
              iconSize={22}
              sx={{
                minWidth: '48px',
                height: '48px',
                borderRadius: '99px',
                position: 'absolute',
                right: 0,
                bottom: 0,
              }}
              onClick={handleOpenConfirmation}
            />
          )}
          <ConfirmationModal
            title={t('form.deleteOffering`')}
            subtitle={t('form.deleteOfferingSubtitle')}
            cancelButtonText={t('form.cancel')}
            submitButtonText={t('form.delete')}
            cancelButtonClick={handleCloseConfirmation}
            submitButtonClick={handleDelete}
            open={openConfirmation}
          />
        </Box>
        <Box sx={{ marginTop: '44px' }}>
          <MoreOfferings talentId={talentId} isMyOfferings={isMyOffer} />
        </Box>
      </GradientCard>
    );
  }
);

export default OfferingDetailsSection;
