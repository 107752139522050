import useDebouncedSearch from '@/hooks/useDebouncedSearch';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, debounce } from '@mui/material';
import { StyledLandingTextField } from '@/styled';
import { BoxIcon } from '@/components/forms/FormSocialLinks';
import SearchIcon from '@/assets/icons/search_icon.svg?react';
import { useAuthStore } from '@/store/authStore';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import { usePositionsQuery } from '@/api/user/queries';
import { useTranslation } from 'react-i18next';
import { isHirer } from '@/utils/roleCheck.ts';

type LandingSearchAutoSuggestProps = {
  positions: any[];
  onDebounce?: (value: string) => void;
  onSearchFocused: () => void;
  searchFocused: boolean;
};

const LandingSearchAutoSuggest = ({
  searchFocused,
  positions,
  onDebounce,
  onSearchFocused,
}: LandingSearchAutoSuggestProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = useAuthStore((state) => state.isAuth);
  const role = useAuthStore((state) => state.role);
  const setLandingSearchValue = useAuthStore(
    (state) => state.setLandingSearchValue
  );

  const [internalValue, setInternalValue] = useState('');
  const [value, setValue] = useState('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onChangeValue = (value: string) => {
    setValue(value);
  };

  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500
      ),
    []
  );

  const onSelectOption = (value: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setLandingSearchValue(value);
      if (isAuth) {
        navigate(
          `/${PAGE_ROUTES.Explore}/${
            isHirer(role)
              ? PAGE_ROUTES.ExploreOfferings
              : PAGE_ROUTES.ExploreJobs
          }?query=${value}`
        );
      } else {
        setLandingSearchValue(value);
        navigate(`/${PAGE_ROUTES.Registration}`);
      }
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        position: searchFocused ? 'absolute' : 'static',
        width: '100%',
        transition: 'top 1s ease, position 1s ease',
        top: { sm: 300, xs: 300, md: 315, lg: 350 },
        ...(searchFocused && { top: 0 }),
      }}
    >
      <Autocomplete
        freeSolo
        open={searchFocused && !!positions?.length}
        onFocus={onSearchFocused}
        options={positions}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.label
        }
        filterOptions={(x) => x}
        autoComplete
        value={{ value: value || '', label: value || '' }}
        inputValue={internalValue}
        onInputChange={(_, newInputValue, reason) => {
          if ((reason === 'input' || reason === 'clear') && onDebounce) {
            setInternalValue(newInputValue);
            handleDebouncedValue(newInputValue, onDebounce);
          }
        }}
        popupIcon={null}
        onChange={(_, newValue, reason) => {
          if (
            typeof newValue === 'object' &&
            newValue !== null &&
            'value' in newValue &&
            'label' in newValue
          ) {
            onChangeValue(newValue.value || '');
            if (reason === 'selectOption') {
              setInternalValue(newValue.label);
              onSelectOption(newValue.label);
            }
          }
        }}
        slotProps={{
          clearIndicator: {
            sx: {
              marginRight: '20px',
              '& svg': {
                color: 'black', // Default color
                '&:hover': {
                  color: 'black', // Hover color
                },
                width: '45px', // Adjust icon size
                height: '45px', // Adjust icon size
              },
            },
          },
          popper: {
            sx: {
              '& .MuiAutocomplete-paper': {
                boxShadow: 'none',
                maxHeight: '900px',
                border: 0,
                overflowY: 'auto',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 50,
                borderBottomRightRadius: 50,
              },
              '& .MuiAutocomplete-listbox': {
                bgcolor: 'white',
                boxShadow: 'none',
                maxHeight: '470px',
                border: 0,
                overflowY: 'auto',
              },
              '& .MuiAutocomplete-option': {
                paddingX: '70px !important',
                paddingY: '10px',
                fontSize: '25px',
                transition: 'background-color 0.2s',
                '&:hover': {
                  bgcolor: '#f4f4f4',
                },
                '&[aria-selected="true"]': {
                  bgcolor: '#e0f7fa',
                  fontWeight: 'bold',
                },
              },
            },
          },
        }}
        renderInput={(params) => (
          <Box sx={{ position: 'relative' }}>
            <StyledLandingTextField
              isopen={Number(searchFocused && !!positions?.length)}
              placeholder={t('landingPage.searchPlaceholder')}
              {...params}
            />
            <BoxIcon sx={{ left: '20px' }}>
              <SearchIcon style={{ color: '#4D63F5' }} />
            </BoxIcon>
          </Box>
        )}
      />
    </Box>
  );
};

const LandingSearchInput = ({
  onSearchFocused,
  searchFocused,
}: {
  searchFocused: boolean;
  onSearchFocused: () => void;
}) => {
  const { searchValue, handleDebounceSearchValue } = useDebouncedSearch();

  const { data: positions } = usePositionsQuery(searchValue);

  return (
    <LandingSearchAutoSuggest
      searchFocused={searchFocused}
      onSearchFocused={onSearchFocused}
      onDebounce={handleDebounceSearchValue}
      positions={positions || []}
    />
  );
};

export default LandingSearchInput;
