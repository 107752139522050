import { create } from 'zustand';

type State = {
  user: any;
  isActiveRoom: boolean;
};

type Actions = {
  setUser: (user: any) => void;
};

export const userInitiateRoomUserStore = create<State & Actions>((set) => ({
  user: null,
  isActiveRoom: true,
  setUser: (user) => {
    set(() => ({
      user,
    }));
  },
}));
