import ProfileWrapper from '@/components/common/ProfileWrapper';
import { useAuthStore } from '@/store/authStore.ts';
import { useUserProfile } from '@/api/user/queries.ts';
import LocationIcon from '@/assets/icons/location_icon.svg?react';
import { Box, Typography } from '@mui/material';
import { StyledTruncatedTypography } from '@/styled';
import SocialLinksButtons from '@/components/common/SocialLinksButtons';
import { getLocationString } from '@/utils/getLocationString.ts';
import { useTranslation } from 'react-i18next';
import EditHirerProfile from '@/pages/ProfilePage/components/hirer/EditHirerProfile.tsx';
import ProfileAvatar from '@/pages/ProfilePage/components/ProfileAvatar.tsx';

const HirerProfileInfo = () => {
  const isAuth = useAuthStore((state) => state.isAuth);
  const { data: profile } = useUserProfile(isAuth);
  const { t } = useTranslation();

  const avatarSrc = profile?.photo?.url ?? '';
  const name = profile?.displayName ?? '';
  const parsedLocation = profile?.location
    ? getLocationString(profile.location)
    : '';

  return (
    <ProfileWrapper sx={{ padding: '0 0 40px 0' }}>
      <Box
        sx={{
          width: '100%',
          padding: '30px 40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {profile && (
          <Box sx={{ position: 'absolute', top: '28px', right: '28px' }}>
            <EditHirerProfile profile={profile} />
          </Box>
        )}
        <ProfileAvatar verified={!!profile?.verified} name={name} avatarSrc={avatarSrc} />
        <StyledTruncatedTypography
          sx={{
            width: '100%',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '22px',
          }}
          variant={'poppins30Semibold'}
        >
          {name}
        </StyledTruncatedTypography>
        <Typography
          variant={'poppins20Regular'}
          sx={{ color: '#92979D', textAlign: 'center' }}
        >
          {profile?.position}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '3px',
            marginTop: '8px',
            alignItems: 'center',
          }}
        >
          <LocationIcon style={{ width: '22px', height: '22px' }} />
          <Typography sx={{ textAlign: 'center' }} variant={'poppins20Medium'}>
            {parsedLocation}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '18px',
            display: 'flex',
            gap: { xs: '20px', sm: '20px', md: '40px', lg: '45px' },
          }}
        >
          <SocialLinksButtons socials={profile?.socialLinks || []} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '22px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '9px',
          backgroundColor: 'gray5',
        }}
      >
        <Typography variant={'poppins18Medium'} sx={{ color: '#92979D' }}>
          {t('form.dating')}:
        </Typography>
        <Typography variant={'poppins20Medium'}>
          {profile?.dating ? t('form.preferred') : t('common.no')}
        </Typography>
      </Box>
    </ProfileWrapper>
  );
};

export default HirerProfileInfo;
