import { Box, IconButton } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import EmojiIcon from '@/assets/icons/emoji_icon.svg?react';

type Props = {
  handleEmojiSelect: (emoji: any) => void;
};

const EmojiSection = ({ handleEmojiSelect }: Props) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if (pickerRef.current && !pickerRef.current.contains(e.target as Node)) {
      setIsEmojiPickerOpen(false);
    }
  };
  useEffect(() => {
    if (isEmojiPickerOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isEmojiPickerOpen]);
  return (
    <>
      {isEmojiPickerOpen && (
        <Box
          ref={pickerRef}
          sx={{
            position: 'absolute',
            bottom: '60px',
            right: '20px',
            zIndex: 10,
          }}
        >
          <EmojiPicker
            onEmojiClick={handleEmojiSelect}
            searchDisabled
            autoFocusSearch={false}
          />
        </Box>
      )}
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setIsEmojiPickerOpen((prev) => !prev);
        }}
      >
        <EmojiIcon />
      </IconButton>
    </>
  );
};

export default EmojiSection;
