import React, { createContext, useContext, useRef } from 'react';
import { Box, SxProps } from '@mui/material';

const ScrollContext = createContext<React.RefObject<HTMLElement> | null>(null);

export const ScrollProvider = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  const scrollContainerRef = useRef<HTMLElement>(null);
  return (
    <ScrollContext.Provider value={scrollContainerRef}>
      <Box
        ref={scrollContainerRef}
        sx={{
          overflowY: 'auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          ...sx,
        }}
      >
        {children}
      </Box>
    </ScrollContext.Provider>
  );
};

export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScrollContext must be used within a ScrollProvider');
  }
  return context;
};
